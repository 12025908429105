import React, { useState, useEffect } from 'react';
import { ITopNavBarComponentProps } from "./types"
import { NotificationsIcon } from "../../../svg-icons/icon-notifications"
import { SettingsIcon } from "../../../svg-icons/icon-settings"
import { PersonIcon } from "../../../svg-icons/icon-person"
import Drawer from '@mui/material/Drawer';
import FormLabeledInput from '../FormComponents/FormLabeledInput/FormLabeledInput';
import classNames from "classnames"
import { Redirect, Link } from 'react-router-dom';
import styles from './top-nav-bar.module.scss';
import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
import { CloseRemoveIcon } from '../../../svg-icons/icon-close-remove';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthData, getIsAuthFetching } from '../../../api/selectors/authSelector'
import { getRefreshToken, getAccessToken } from "../../../api/selectors/tokenSelector";
// import { useTranslation } from 'react-i18next';
import SettingsModal from './settings-modal';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Button } from "finergy-kit";
import { useTranslation } from 'react-i18next';
import CircularProgress from "@mui/material/CircularProgress";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


function TopNavBarComponent({ navBarTitle, className, breadcrumbs = null, onExit  }: ITopNavBarComponentProps) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [redirect, setRedirect] = useState(false)
    const [showSettingsModal, setShowSettingsModal] = useState(false)
    const data = useSelector(getAuthData);
    const authData = JSON.parse(data);
    const token = useSelector(getRefreshToken)
    const accessToken = useSelector(getAccessToken)

    const isAuthFetching = useSelector(getIsAuthFetching);

    useEffect(() => {
        (!token || !accessToken) && setRedirect(true)
    }, [token, accessToken])


    const renderExitModal = () => {
        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className={styles.custom_modal_container}>
                    <DialogTitle className={styles.setting_modal_title}>
                        {t("Logout")}
                    </DialogTitle>
                    <DialogContent className={styles.modal_content}>
                        <DialogContentText
                            id="alert-dialog-slide-description"
                            className={styles.logout_header}
                        >
                            {t("Are_you_sure")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={styles.action_buttons}>
                        <Button
                            onClick={() => onExit()}
                            variant="tertiary"
                            label={t("Exit")}
                            size="l"
                            disabled={isAuthFetching}
                            iconPosition="off"
                            icon={
                                <CircularProgress className="circular-progress" size={18} />
                            }
                            iconOnly={isAuthFetching}
                            id={"BACK_BUTTON"}
                            className={classNames({
                                [`${styles.logout_button}`]: true,
                            })}
                        />
                        <Button
                            onClick={() => setOpen(false)}
                            variant="tertiary"
                            label={t("Cancel")}
                            // disabled={isServiceModeFetching}
                            className={styles.exit_button}
                            size="l"
                            iconPosition="off"
                            id={"ON_EXIT"}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        );
    };
    return (
        <>
 {renderExitModal()}
            {showSettingsModal && <SettingsModal {...{ setShowSettingsModal, showSettingsModal }} />}
            {redirect && <Redirect to="/" />}
            <div
                className={classNames({
                    [`${styles.top_nav_bar_wrapper}`]: true,
                    [className]: true
                })}>
                <div className={styles.top_bar_page_name}>
                    {breadcrumbs && breadcrumbs.step2Label && <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
                        <Link to={breadcrumbs.step1Url}>
                            <Typography>
                                {breadcrumbs.step1Label}
                            </Typography>
                        </Link>
                        / {breadcrumbs.step2Label && <Typography>{breadcrumbs.step2Label}</Typography>}
                    </Breadcrumbs>}
                    <Typography className={styles.page_title} component={'span'} variant={'body2'}>{navBarTitle}</Typography>


                </div>
                <div className={styles.top_bar_page_user_wrapper}>
                    <div className={styles.notification_icon_wrapper} >
                        <NotificationsIcon />
                    </div>
                    <div className={styles.settings_icon_wrapper} >
                        <SettingsIcon onClick={() => setShowSettingsModal(true)} />
                    </div>

                    <div className={styles.user_name_info_wrapper}>
                        <div className={styles.user_login}>
                            <span>{authData?.phoneNumber}</span>
                            <PersonIcon />
                        </div>
                        <div className={styles.exit_btn} onClick={() => setOpen(true)}>{t("Exit")}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopNavBarComponent;
