/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { apiRequest } from "../../api/services/Api";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import classNames from "classnames";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import dayjs from "dayjs";
import { amountFormatter } from "../../constants";
import styles from "./transactions-details.module.scss";
import { enqueueSnackbar } from "notistack";

const TerminalsDetails = () => {
  const { t } = useTranslation();
  const { txId } = useParams();
  const defaultTrxInfo = {
    id: null,
    posId: null,
    terminalId: null,
    sellerId: null,
    sellerName: null,
    storeId: null,
    storeName: null,
    amount: null,
    ccy: null,
    mccCode: null,
    referenceNumber: null,
    approvalCode: null,
    status: null,
    comment: null,
    txDate: null,
    merchantStoreName: null,
    merchantStoreAddress: null,
    swiftMessageType: null,
    swiftMessageId: null,
    swiftPayerBank: null,
    paymentSystem: null,
  };

  const [isFetching, setIsFetching] = useState(true);
  const [transactionInfo, setTrxInfo] = useState({ ...defaultTrxInfo });
  const [redirect, setRedirect] = useState(false);

  console.log('transactionInfo', transactionInfo);

  const accessToken = useSelector(getAccessToken);

  // const getTrxInfo = () => {
  //   setIsFetching(true);
  //   axios
  //     .get(`/sap/api/v1/tx/${txId}`)
  //     .then((response) => {
  //       const { data } = response;
  //       setTrxInfo(data);
  //       setIsFetching(false);
  //     })
  //     .catch((err) =>
  //       console.log("err", err));
  // };

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      setIsFetching(true);
      try {
        const { status, response } = await apiRequest(
          "get",
          `/cap/api/v1/tx/${txId}`,
          null
        );

        if (status === 200 || status === 201) {
          const { data } = response;
          setTrxInfo(data);
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error) {
        console.error("Failed to fetch transaction details:", error);
      } finally {
        setIsFetching(false);
      }
    };

    accessToken && fetchTransactionDetails();
  }, [accessToken, txId]);

  const navBarTitle = `#${txId}`;

  const breadcrumbs = {
    step1Label: t("Transactions"),
    step1Url: "/app/transactions",
    step2Label: `#${txId}`,
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      <div>
        {redirect && <Redirect to={"/app/transactions/"} />}
        <div
          className={classNames({
            // [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.info_block}>
                <div className={styles.info_block_title}>
                  <span>{t("Transactions_details")}</span>
                </div>
                <div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Date")}</label>
                    <div className={styles.block_value}>
                      {dayjs(transactionInfo.txDate).format("DD/MM/YYYY, HH:mm")}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_id")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.id}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Terminal_id")}</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/terminals/${transactionInfo.posId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.terminalId}
                      </Link>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>POS ID</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/terminals/${transactionInfo.posId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.posId}
                      </Link>
                    </div>
                  </div>



                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Amount")}</label>
                    <div
                      className={classNames({
                        [styles.block_value]: true,
                        [styles.table_cell_summ]: true,
                      })}
                    >
                      {amountFormatter(transactionInfo.amount)}
                      <span className={styles.uppercase}>
                        {transactionInfo.ccy}
                      </span>
                    </div>
                  </div>


                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Seller")}</label>
                    <div
                      className={classNames({
                        [styles.block_value]: true,
                        [styles.table_cell_summ]: true,
                      })}
                    >

                      <Link
                        to={`/app/sellers/${transactionInfo.sellerId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.sellerName}
                      </Link>
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Reference_number")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo?.referenceNumber || " - "}
                    </div>
                  </div>



                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("MCC")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.mccCode}
                    </div>
                  </div>



                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Approval_code")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.approvalCode || " - "}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Store")}</label>
                    <div className={styles.block_value}>
                      <Link
                        to={`/app/stores/${transactionInfo.storeId}`}
                        className={styles.no_underline}
                      >
                        {transactionInfo.merchantStoreName || " - "}
                      </Link>
                    </div>
                  </div>



                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Status")}</label>
                    <div className={styles.block_value}>
                      {UseErrorLabel(transactionInfo.status, t)}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Comment")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.comment || " - "}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_store")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.merchantStoreName || " - "}
                    </div>
                  </div>

                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_store_adres")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.merchantStoreAddress || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_swift_type")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.swiftMessageType || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_swift_id")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.swiftMessageId || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_swift_bank")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.swiftPayerBank || " - "}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      [styles.info_block_input_container]: true,
                    })}
                  >
                    <label>{t("Transaction_pay_system")}</label>
                    <div className={styles.block_value}>
                      {transactionInfo.paymentSystem || " - "}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </AppMainLayout>
  );
};

export default TerminalsDetails;
