/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
// import { Button } from "finergy-kit";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { apiRequest } from "../../api/services/Api";
import {
  getAccessToken,
  getRefreshToken,
} from "../../api/selectors/tokenSelector";
import { setPeriod } from "../../api/actionCreators/mainActionCreator";
import { getPeriod } from "../../api/selectors/mainSelector";
import Pagination from "../../infrastructure/components/Pagination";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { CalendarIcon } from "../../svg-icons/icon-calendar";
import Button from "../../infrastructure/components/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useDebounce from '../../hooks/useDebounce';
import CircularProgress from "@mui/material/CircularProgress";

import classNames from "classnames";
import {
  CUSTOM_PERIOD,
  LAST_MONTH,
  LAST_THREE_MONTHS,
  QUARTER,
  filtersTransactions,
  capitalize,
  FORMAT,
  amountFormatter,
} from "../../constants";
import { setPageSize, setChangeDate } from "../../api/actionCreators/mainActionCreator";
import { getTransactionPageSize, getDateFrom, getDateTo } from "../../api/selectors/mainSelector";
import { pages } from "../../constants";

import 'dayjs/locale/en';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';

import { getCurrentLanguage } from "../../api/selectors/languageSelector";

import styles from "./transactions.module.scss";
import { enqueueSnackbar } from "notistack";

const Transactions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector(getCurrentLanguage);

  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState("all");
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [customPeriodDialogOpen, setCustomPeriodDialogOpen] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const pageSize = useSelector(getTransactionPageSize);

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const period = useSelector(getPeriod);
  const dateTo = useSelector(getDateTo);
  const dateFrom = useSelector(getDateFrom);


  const [modalDateTo, setModalDateTo] = useState<Dayjs | string>(
    dayjs(dateTo).format(FORMAT)
  );
  const [modalDateFrom, setModalDateFrom] = useState<Dayjs | string>(
    dayjs(dateFrom).format(FORMAT)
  );


  const fetchTransactions = async (searchText,
    currentPage,
    pageSize,
    filter) => {
    setIsFetching(true);
    const f = filter === "all" ? " " : filter;

    try {
      const { status, response } = await apiRequest(
        "get",
        `/cap/api/v1/tx?offset=${currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}&dateFrom=${dateFrom}&dateTo=${dateTo}&txState=${f}`,
        null
      );
      if (status === 200) {
        const {
          data: {
            txList = [],
            pagination: { totalCount },
          },
        } = response;


        setRows(txList || []);
        // setRows(test || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    } finally {
      setIsFetching(false);
      setShowPagination(true)
    }
  };
  useDebounce(() => {
    fetchTransactions(searchText,
      currentPage,
      pageSize,
      filter)
  }, [searchText,
    currentPage,
    pageSize,
    filter,
    period,
    token,
    accessToken,
    dateTo,
    dateFrom,], 500);

  useEffect(() => {
    setShowPagination(false)
  }, [searchText, pageSize, filter, period, dateTo, dateFrom, currentPage])


  const renderTransactionsCells = () => {
    return [
      {
        field: "txDate",
        headerName: t("Date"),
        cellClassName: styles.table_cell_date,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return <span className={styles.no_color}>{dayjs(row.txDate).format("DD/MM/YYYY, HH:mm")}</span>
        },
      },
      {
        field: "swiftMessageId",
        headerName: t("Transaction_swift_id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return row.swiftMessageId;
        },
      },
      
      {
        field: "id",
        headerName: t("Transaction_id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/transactions/${row.id}`}
              className={styles.no_underline}
            >
              {row.id}
            </Link>
          );
        },
      },
      {
        field: "terminalId",
        headerName: t("Terminal_id"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <span
              className={styles.no_color}
            >
              {row.terminalId}
            </span>
          );
        },
      },
      {
        field: "amount",
        headerName: t("Amount"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <div className={styles.no_color}>
              {amountFormatter(row.amount)}
              <span className={styles.uppercase}>{row.ccy}</span>
            </div>
          );
        },
      },
      {
        field: "referenceNumber",
        headerName: t("Reference_number"),
        cellClassName: styles.table_cell_merchant,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantName || "-"}
            </Link>
          );
        },
      },
      {
        field: "storeName",
        headerName: t("Store"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_summ,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/stores/${row.storeId}`}
              className={styles.no_underline}
            >
              {row.storeName || "-"}
            </Link>
          );
        },
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return UseErrorLabel(row.status, t,);
        },
      },
      {
        field: "comment",
        headerName: t("Comment"),
        cellClassName: styles.table_cell,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return row.comment || " - ";
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setCurrentPage(0);
    dispatch(setPageSize(pages.TRANSACTION_PAGE, size));
  };

  const selectThisQuarter = () => {
    dayjs.extend(quarterOfYear);
    const dateFrom = dayjs().startOf("day").startOf("quarter").format(FORMAT);
    const dateTo = dayjs().endOf("day").format(FORMAT);

    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
  };

  const selectLastMonth = () => {
    const dateFrom = dayjs().startOf("day").subtract(31, "day").format(FORMAT);
    const dateTo = dayjs().endOf("day").format(FORMAT);

    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
    setCurrentPage(0)
  };

  const selectLastThreeMonths = () => {
    const dateFrom = dayjs().startOf("day").subtract(90, "day").format(FORMAT);
    const dateTo = dayjs().endOf("day").format(FORMAT);
    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
    setCurrentPage(0)
  };

  const selectCustomPeriod = () => {
    const dateFrom = dayjs(modalDateFrom).startOf("day").format(FORMAT);
    const dateTo = dayjs(modalDateTo).endOf("day").format(FORMAT);

    dispatch(setChangeDate("dateFrom", dateFrom));
    dispatch(setChangeDate("dateTo", dateTo));
    dispatch(setPeriod(4));
    setCustomPeriodDialogOpen(false);
    setCurrentPage(0)
  };

  const handlePeriodChange = (event) => {
    event.target.value !== 4 && dispatch(setPeriod(event.target.value));


    switch (event.target.value) {
      case LAST_MONTH:
        selectLastMonth();
        break;
      case LAST_THREE_MONTHS:
        selectLastThreeMonths();
        break;
      case QUARTER:
        selectThisQuarter();
        break;
      case CUSTOM_PERIOD:
        setCustomPeriodDialogOpen(true);
        break;
      default:
        selectLastMonth();
        break;
    }
  };

  const renderFilterItems = () => {
    const content = filtersTransactions.map((filterName) => {
      return (
        <button
          className={classNames({
            [`${styles.filter_button}`]: true,
            [`${styles.filter_button_active}`]: filter === filterName,
          })}
          onClick={() => { setFilter(filterName); setCurrentPage(0) }}
        >
          {t(`${capitalize(filterName)}_filter`)}
        </button>
      );
    });

    return content;
  };

  const renderCustmDialog = () => {
    return (
      <Dialog open={customPeriodDialogOpen}>
        <DialogTitle id="custom-period-dialog">
          {t("Custom_period")}
        </DialogTitle>
        <DialogContent className={styles.content_visible}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language ?? 'ro'}>
            <DatePicker
              label={t("Date_from")}
              value={dayjs(modalDateFrom)}
              onChange={(newValue) => {
                setModalDateFrom(newValue);
              }}
              disableFuture
              maxDate={dayjs(modalDateTo)}
              format={"DD/MM/YYYY"}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language ?? 'ro'}>
            <DatePicker
              label={t("Date_to")}
              value={dayjs(modalDateTo)}
              onChange={(newValue) => {
                setModalDateTo(newValue);
              }}
              minDate={dayjs(modalDateFrom)}
              format={"DD/MM/YYYY"}
              disableFuture
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            variant="primary"
            onClick={() => { selectCustomPeriod(); setCurrentPage(0) }}
            label={t("Search")}
          />

          <Button
            variant="primary"
            onClick={() => setCustomPeriodDialogOpen(false)}
            label={t("Cancel")}
          />
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <AppMainLayout navBarTitle={t("Transactions")}>
      {renderCustmDialog()}
      <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
        <InputLabel htmlFor="outlined-adornment-amount">
          {t("Search")}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={<SearchIcon />}
          label={t("Search")}
          value={searchText}
          onChange={(e) => { setCurrentPage(0); setSearchText(e.target.value) }}
        />
      </FormControl>

      <div className={styles.calendar_and_filters_container}>
        <FormControl className={styles.select_period}>
          <CalendarIcon />
          <label>{t("Period")}</label>
          <Select
            value={period}
            name="selected-period"
            onChange={handlePeriodChange}
          >
            <MenuItem value={LAST_MONTH}>{t("Last_month")}</MenuItem>
            <MenuItem value={LAST_THREE_MONTHS}>
              {t("Last_three_months")}
            </MenuItem>
            <MenuItem value={QUARTER}>{t("Quarter")}</MenuItem>
            <Divider />
            <MenuItem
              value={CUSTOM_PERIOD}
              onClick={() => setCustomPeriodDialogOpen(true)}
            >
              {t("Custom_period")}
            </MenuItem>
          </Select>
          <div className={styles.transactions_dates}>
            {dayjs(dateFrom).format("DD/MM/YYYY")} -{" "}
            {dayjs(dateTo).format("DD/MM/YYYY")}
          </div>
        </FormControl>

        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      <DataTableComponent
        checkboxSelection={false}
        columns={renderTransactionsCells()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.terminal_table}
        {...{ rows }}
      />
      <div className={styles.pagination_container}>
        {!isFetching && showPagination ? (
          <Pagination
            onClick={paginationClick}
            onChangePageSize={pageSizeClick}
            total={totalCount}
            pageIndex={currentPage}
            pageSize={pageSize}
          />
        ) : <CircularProgress className="circular-progress" size={18} />}
      </div>

    </AppMainLayout>
  );
};

export default Transactions;
